const ERROR_ALERT_VISIBLE = 'ERROR_ALERT_VISIBLE';
const ERROR_ALERT_INVISIBLE = 'ERROR_ALERT_INVISIBLE';
const SUCCESS_ALERT_VISIBLE = 'SUCCESS_ALERT_VISIBLE';
const SUCCESS_ALERT_INVISIBLE = 'SUCCESS_ALERT_INVISIBLE';

const initialState = {
  isVisible: false,
  isSuccessAlertVisible: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_ALERT_VISIBLE:
      return {
        ...state,
        text: action.text,
        isVisible: true,
      };

    case ERROR_ALERT_INVISIBLE:
      return {
        ...state,
        text: null,
        isVisible: false,
      };

    case SUCCESS_ALERT_VISIBLE:
      return {
        ...state,
        text: action.text,
        isSuccessAlertVisible: true,
      };

    case SUCCESS_ALERT_INVISIBLE:
      return {
        ...state,
        text: null,
        isSuccessAlertVisible: false,
      };

    default:
      return state;
  }
}

export function showErrorAlert(text) {
  return {
    type: ERROR_ALERT_VISIBLE,
    text,
  };
}

export function hideValidationAlert() {
  return {
    type: ERROR_ALERT_INVISIBLE,
  };
}

export function showSuccessAlert(text) {
  return {
    type: SUCCESS_ALERT_VISIBLE,
    text,
  };
}

export function hideSuccessAlert() {
  return {
    type: SUCCESS_ALERT_INVISIBLE,
  };
}
