import Cookies from 'js-cookie';

const RESET_SHIPS_ERR = 'RESET_SHIPS_ERR';

const GET_ALL_SHIPS = 'GET_ALL_SHIPS';
const GET_ALL_SHIPS_SUCCESS = 'GET_ALL_SHIPS_SUCCESS';
const GET_ALL_SHIPS_FAIL = 'GET_ALL_SHIPS_FAIL';

const CHANGE_CURRENT_SHIP = 'CHANGE_CURRENT_SHIP';

const SET_ACTIVE_CAMERA_STREAM_ID = 'SET_ACTIVE_CAMERA_STREAM_ID';

const SET_HEADING = 'SET_HEADING';

const initialState = {
  shipsError: {},
  currentShipId: null,
  ships: [],
  loading: false,
  activeCameraStreamId: null,
};

export default function reducer() {
  let cookieOptions = {
    domain: process.env.DOMAIN_NAME || window.location.hostname,
  };

  initialState.currentShipId = JSON.parse(
    Cookies.get('tototheoClientCurrentShipId') || 'null'
  );

  return function(state = initialState, action = {}) {
    switch (action.type) {
      case GET_ALL_SHIPS: {
        return {
          ...state,
          loading: true,
        };
      }
      case GET_ALL_SHIPS_SUCCESS: {
        return {
          ...state,
          loading: false,
          ships: action.result,
        };
      }
      case GET_ALL_SHIPS_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case CHANGE_CURRENT_SHIP:
        Cookies.set(
          'tototheoClientCurrentShipId',
          JSON.stringify(action.shipId),
          {
            ...cookieOptions,
          }
        );
        return {
          ...initialState,
          currentShipId: action.shipId,
        };

      case RESET_SHIPS_ERR:
        return {
          ...state,
          authError: {},
        };

      case SET_ACTIVE_CAMERA_STREAM_ID:
        return {
          ...state,
          activeCameraStreamId: action.cameraId,
        };

      case SET_HEADING:
        let heading = parseInt(action.heading, 10);

        return {
          ...state,
          heading,
        };

      default:
        return state;
    }
  };
}

export function getAllShips() {
  return {
    types: [GET_ALL_SHIPS, GET_ALL_SHIPS_SUCCESS, GET_ALL_SHIPS_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_CLIENT_URL}/ships`),
  };
}

export function changeCurrentShipId(id) {
  return {
    type: CHANGE_CURRENT_SHIP,
    shipId: id,
  };
}

export function resetShipsErr() {
  return {
    type: RESET_SHIPS_ERR,
  };
}

export function setHeading(heading) {
  return {
    type: SET_HEADING,
    heading,
  };
}

export function setActiveCameraStreamId(cameraId) {
  return {
    type: SET_ACTIVE_CAMERA_STREAM_ID,
    cameraId,
  };
}
