import humps from 'humps';

const GET_ALERTS = 'GET_ALERTS';
const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
const GET_ALERTS_FAIL = 'GET_ALERTS_FAIL';

const initialState = {
  alertsError: {},
  currentShipId: null,
  alerts: [],
  loading: false,
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALERTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ALERTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        alerts: humps.camelizeKeys(action.result.alerts),
      };
    }
    case GET_ALERTS_FAIL:
      return {
        ...state,
        isErrorVisible: true,
        loading: false,
        alertsError: action.error,
      };

    default:
      return state;
  }
}

export function getAlerts(shipId) {
  return {
    types: [GET_ALERTS, GET_ALERTS_SUCCESS, GET_ALERTS_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_CLIENT_URL}/ships/${shipId}/alerts`
      ),
  };
}
