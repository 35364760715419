/**
 */

THREE.TechShader = {
  uniforms: {
    //"mRefractionRatio": { value: 1.02 },
    //"mFresnelBias": { value: 0.1 },
    //"mFresnelPower": { value: 2.0 },
    //"mFresnelScale": { value: 1.0 },
    //"tCube": { value: null }
    color: {
      type: 'c',
      value: new THREE.Color(0xff000), //0x99aaff
    },
    power: {
      type: 'f',
      value: 4.0,
    },
    //"time": {
    //    type: "f",
    //    value:0.0
    //}
  },

  vertexShader: [
    //"uniform float time;",

    //"attribute vec3 face_center;",
    //"attribute vec3 face_offset_time;",

    'attribute vec3 center;',
    'varying vec3 vCenter;',

    //"uniform float mRefractionRatio;",
    //"uniform float mFresnelBias;",
    //"uniform float mFresnelScale;",
    //"uniform float mFresnelPower;",

    //"varying vec3 vReflect;",
    //"varying vec3 vRefract[3];",
    //"varying float vReflectionFactor;",

    'varying vec3 viewVector;',
    'varying vec3 worldNormal;',

    'void main() {',

    'vCenter = center;',

    //"	vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
    //"	vec4 worldPosition = modelMatrix * vec4( position, 1.0 );",

    //"	vec3 worldNormal = normalize( mat3( modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz ) * normal );",

    //"	vec3 I = worldPosition.xyz - cameraPosition;",

    //"	vReflect = reflect( I, worldNormal );",
    //"	vRefract[0] = refract( normalize( I ), worldNormal, mRefractionRatio );",
    //"	vRefract[1] = refract( normalize( I ), worldNormal, mRefractionRatio * 0.99 );",
    //"	vRefract[2] = refract( normalize( I ), worldNormal, mRefractionRatio * 0.98 );",
    //"	vReflectionFactor = mFresnelBias + mFresnelScale * pow( 1.0 + dot( normalize( I ), worldNormal ), mFresnelPower );",

    //"	gl_Position = projectionMatrix * mvPosition;",

    //"vec3 pos = position + (face_center - position) * clamp(time-face_offset_time, 0.0, 1.0);",

    'vec4 worldPosition = modelMatrix * vec4(position, 1.0);',
    'worldNormal = mat3(modelMatrix) * normal;',
    'gl_Position = projectionMatrix * viewMatrix * worldPosition;',
    'viewVector = cameraPosition - worldPosition.xyz;',

    '}',
  ].join('\n'),

  fragmentShader: [
    '#extension GL_OES_standard_derivatives: enable',

    'varying vec3 vCenter;',

    //"varying vec3 vReflect;",
    //"varying vec3 vRefract[3];",
    //"varying float vReflectionFactor;",

    'varying vec3 viewVector;',
    'varying vec3 worldNormal;',

    'uniform float power;',
    'uniform vec3 color;',

    'void main() {',

    'vec3 d = fwidth(vCenter.xyz);',
    'vec3 a3 = smoothstep(vec3(0.0), d * 2.0, vCenter.xyz);',
    'float edgeFactorTri = min(min(a3.x, a3.y), a3.z);',
    'vec3 vEdge = mix(vec3(1.0), vec3(0.2), edgeFactorTri);',

    //"vec3 d = fwidth(vCenter.xyz);",
    //"vec3 a3 = smoothstep(vec3(0.0), d * 1.5, vCenter.xyz);",
    //"float mid = min(min(a3.x, a3.y), a3.z);",
    //"vec3 edge = mix(vec3(1.0), vec3(0.2), edgeFactorTri());",

    //"	vec4 reflectedColor = textureCube( tCube, vec3( -vReflect.x, vReflect.yz ) );",
    //"	vec4 refractedColor = vec4( 1.0 );",

    //"	refractedColor.r = textureCube( tCube, vec3( -vRefract[0].x, vRefract[0].yz ) ).r;",
    //"	refractedColor.g = textureCube( tCube, vec3( -vRefract[1].x, vRefract[1].yz ) ).g;",
    //"	refractedColor.b = textureCube( tCube, vec3( -vRefract[2].x, vRefract[2].yz ) ).b;",

    //"	gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );",

    //"	gl_FragColor = vec4(color,1.0);",

    'vec3 viewDir = normalize(viewVector);',
    'vec3 normal = normalize(worldNormal);',
    'float fresnel = 1.0 - abs(dot(viewDir, normal));',
    //"fresnel = max(0.1, pow(fresnel, power) * 3.0);",
    'fresnel = pow(fresnel, power) * 3.0 + 0.1;',
    //"float edge_fresnel = pow(fresnel, 10.0) * 4.0;",

    //"float rim = step(0.9, 1.0 - saturate(dot(viewDir, normal)));",

    //"gl_FragColor = vec4(color*fresnel+edge_fresnel, 1.0);",

    //"gl_FragColor = vec4(color*fresnel*fresnel*2.0, 1.0);",

    'gl_FragColor = vec4(color*fresnel+color*vEdge, 1.0);',

    //"gl_FragColor = vec4(vEdge, 1.0);",

    '}',
  ].join('\n'),
};
