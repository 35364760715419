import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppContainer as ReactHotLoader } from 'react-hot-loader';
import getStore from './redux/create';
import getRoutes from './routes/AppRoutes';
import Api from './helpers/api';
import * as serviceWorker from './serviceWorker';

import 'three';
import 'three/examples/js/loaders/FBXLoader';
import 'three/examples/js/postprocessing/EffectComposer';
import 'three/examples/js/postprocessing/ShaderPass';
import 'three/examples/js/postprocessing/SSAARenderPass';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './styles/scss/client.scss';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})

const api = Api();
const dest = document.getElementById('root');
const { store, persistor } = getStore(api);
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

const Component = () => <BrowserRouter>{getRoutes(store)}</BrowserRouter>;

const initRender = InitComponent => {
  render(
    <ErrorBoundary>
      <ReactHotLoader key={Math.random()} warnings={false}>
        <Provider store={store}>
          <PersistGate store={store} persistor={persistor}>
            {InitComponent()}
          </PersistGate>
        </Provider>
      </ReactHotLoader>
    </ErrorBoundary>,
    dest
  );
};

initRender(Component);

if (module.hot) {
  module.hot.accept('./routes/AppRoutes.js', () => initRender(Component));
}

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
