import superagent from 'superagent';
import humps from 'humps';

/**
 *  Http requests
 */
export default function Api() {
  const api = {};
  ['get', 'post', 'put', 'patch', 'del'].forEach(method => {
    api[method] = (path, options) => {
      return new Promise((resolve, reject) => {
        let request = superagent[method](path);

        if (api.headers && api.headers.auth) {
          request.set('Authorization', `Bearer ${api.headers.auth}`);
        }

        if (options && options.params) {
          request.query(options.params);
        }

        if (options && options.attach) {
          request.attach('image', options.attach);
        }

        if (options && options.data) {
          request.send(humps.decamelizeKeys(options.data));
        }

        request.end((err, res) => {
          if (err) {
            reject({ message: (res && res.body) || err.message, res });
          } else {
            resolve(humps.camelizeKeys(res.body));
          }
        });
      });
    };
  });
  return api;
}
