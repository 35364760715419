import * as spinnerActions from '../modules/spinner';

export default function request(client) {
  let authToken;
  let blocked = false;

  const typeShowSpinner = spinnerActions.showSpinner();
  const typeHideSpinner = spinnerActions.hideSpinner();

  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const {
        promise,
        types,
        isSpinnerHidden,
        blockedSpinner,
        ...rest
      } = action;
      if (!promise) {
        return next(action);
      }
      try {
        authToken = getState().auth.user.token;
      } catch (err) {}

      client.headers = {
        auth: authToken,
      };

      const [REQUEST, SUCCESS, FAILURE] = types;
      if (blockedSpinner) {
        blocked = true;
      }
      if (isSpinnerHidden) {
        next({
          ...rest,
          ...typeHideSpinner,
        });
      } else {
        if (!blocked) {
          next({
            ...rest,
            ...typeShowSpinner,
          });
        }
      }

      next({
        ...rest,
        type: REQUEST,
      });
      return promise(client)
        .then(result => {
          if (!blocked) {
            next({
              ...rest,
              ...typeHideSpinner,
            });
          }

          if (blockedSpinner) {
            blocked = false;
          }

          return next({
            ...rest,
            result,
            type: SUCCESS,
          });
        })
        .catch(({ message = {} }) => {
          blocked = false;
          if (!blocked) {
            next({
              ...rest,
              ...typeHideSpinner,
            });
          }

          if (blockedSpinner) {
            blocked = false;
          }

          next({
            ...rest,
            error: message,
            type: FAILURE,
          });

          throw message;
        });
    };
  };
}
