var SceneOne = (function() {
  // constructor

  function polygonLineSurface(amountX, amountY, separation) {
    var geometry = new THREE.Geometry();

    var i = 0;

    for (var ix = 0; ix < amountX; ix++) {
      for (var iy = 0; iy < amountY; iy++) {
        var _x = (ix + 1) * separation - (amountX * separation) / 2; // x
        var _z = (iy - 1) * separation - (amountY * separation) / 2; // z
        var dist = _x * _x + _z * _z;
        geometry.vertices.push(new THREE.Vector3(_x, 1 - dist * 0.000025, _z));

        var _x = (ix + 1) * separation - (amountX * separation) / 2; // x
        var _z = (iy + 1) * separation - (amountY * separation) / 2; // z
        var dist = _x * _x + _z * _z;
        geometry.vertices.push(new THREE.Vector3(_x, 1 - dist * 0.000025, _z));

        var _x = (ix - 1) * separation - (amountX * separation) / 2; // x
        var _z = (iy + 1) * separation - (amountY * separation) / 2; // z
        var dist = _x * _x + _z * _z;
        geometry.vertices.push(new THREE.Vector3(_x, 1 - dist * 0.000025, _z));

        var _x = ix * separation - (amountX * separation) / 2; // x
        var _z = (iy + 1) * separation - (amountY * separation) / 2; // z
        var dist = _x * _x + _z * _z;
        geometry.vertices.push(new THREE.Vector3(_x, 1 - dist * 0.000025, _z));

        i += 3;
      }
    }

    return geometry;
  }

  var cls = function(onReady, modelUrl) {
    //THREEJS
    var _light;
    var _parameters = {
      distance: 400,
      inclination: 0.49,
      azimuth: 0.205,
    };
    var _water;
    var _boat;
    var _cubeCamera;
    var _boat_uniforms;
    var _compass;

    var _line;
    var _grid_uniforms;
    var SEPARATION = 6;
    var AMOUNTX = 64;
    var AMOUNTY = 64;
    var _particles;
    var _line_shader;

    var _root;

    var _active = false;
    let isBridgeView = false;

    //public functions
    this.init = function(window, root) {
      _window = window;
      _root = root;
    };

    this.add = function() {
      //LIGHT
      _light = new THREE.DirectionalLight(0xffffff, 0.8);
      _light.castShadow = false;

      //COMPASS
      var compass_geom = new THREE.PlaneGeometry(360, 360, 8);
      var compass_material = new THREE.MeshBasicMaterial();
      compass_material.map = new THREE.TextureLoader().load(
        '/images/compass.png'
      );
      compass_material.alphaMap = new THREE.TextureLoader().load(
        '/images/compass.png'
      );
      compass_material.transparent = true;

      compass_material.side = THREE.DoubleSide;
      compass_material.blending = THREE.AdditiveBlending;

      _compass = new THREE.Mesh(compass_geom, compass_material);
      _compass.rotation.x = -Math.PI / 2;
      _compass.position.y = 2;
      _compass.rotation.z = Math.PI + Math.PI / 4;

      //WATER
      var waterGeometry = new THREE.PlaneBufferGeometry(10000, 10000);
      _water = new THREE.Water(waterGeometry, {
        textureWidth: 1024,
        textureHeight: 1024,
        waterNormals: new THREE.TextureLoader().load(
          '/textures/waternormals.jpg',
          function(texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }
        ),
        gridTexture: new THREE.TextureLoader().load(
          '/textures/grid_raw.jpg',
          function(texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }
        ),
        alpha: 0.9,
        sunDirection: _light.position.clone().normalize(),
        sunColor: 0x1db287, //0xffffff,
        waterColor: 0x94bbc9,
        distortionScale: 3.7,
        fog: _root.scene.fog !== undefined,
      });
      _water.material.uniforms.size.value = 2;
      _water.rotation.x = -Math.PI / 2;

      ///*
      //POLYGON LINE SURFACE

      //

      function polygonCirclesOnLineSurface(amountX, amountY, separation) {
        var numParticles = amountX * amountY;

        var positions = new Float32Array(numParticles * 3);
        var scales = new Float32Array(numParticles);
        var indexes = new Float32Array(numParticles);

        var i = 0;
        var j = 0;

        for (var ix = 0; ix < amountX; ix++) {
          for (var iy = 0; iy < amountY; iy++) {
            var _x = ix * separation - (amountX * separation) / 2; // x
            var _z = iy * separation - (amountY * separation) / 2; // z

            var dist = _x * _x + _z * _z;

            positions[i] = _x;
            positions[i + 1] = 1 - dist * 0.000025; // y
            positions[i + 2] = _z;

            scales[j] = 2;

            indexes[j] = i * 0.1;

            i += 3;
            j++;
          }
        }

        return { positions, scales, indexes };
      }

      let { positions, scales, indexes } = polygonCirclesOnLineSurface(
        AMOUNTX,
        AMOUNTY,
        SEPARATION
      );

      var geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
      geometry.setAttribute('index', new THREE.BufferAttribute(indexes, 1));

      var shader = THREE.GridShader;
      _grid_uniforms = THREE.UniformsUtils.clone(shader.uniforms);
      _grid_uniforms['color'].value = new THREE.Color(0x607c87); //0x328cba 0x347394
      _grid_uniforms['scale'].value = 5;

      var particle_material = new THREE.ShaderMaterial({
        uniforms: _grid_uniforms,
        depthWrite: true,
        vertexShader: shader.vertexShader,
        fragmentShader: shader.fragmentShader,
      });

      _particles = new THREE.Points(geometry, particle_material);
      _root.scene.add(_particles);

      //

      var line_material = new THREE.LineBasicMaterial({
        color: 0x607c87,
        linewidth: 1,
        linecap: 'round', //ignored by WebGLRenderer
        linejoin: 'round', //ignored by WebGLRenderer
      });

      line_material.onBeforeCompile = function(shader) {
        console.log('onBeforeCompile');

        //console.log(shader.vertexShader);

        shader.uniforms.time = { value: 0 };

        shader.vertexShader =
          `
                    uniform float time;
                ` + shader.vertexShader;

        const token = '#include <begin_vertex>';
        const customTransform = `
                    vec3 transformed = vec3(position);
                    float freq = 0.13;
                    float freq2 = 1.0;
                    float amp = 1.0;
                    float angle = (time + position.x)*freq;
                    float angle2 = (time + position.z)*freq2;
                    transformed.y += sin(angle2)*sin(angle)*amp;
                `;

        shader.vertexShader = shader.vertexShader.replace(
          token,
          customTransform
        );

        _line_shader = shader;
      };

      geometry = polygonLineSurface(AMOUNTX, AMOUNTY, SEPARATION);

      _line = new THREE.LineSegments(geometry, line_material);
      _root.scene.add(_line);

      //BOAT MODEL
      var shader = THREE.TechShader;
      _boat_uniforms = THREE.UniformsUtils.clone(shader.uniforms);
      _boat_uniforms['color'].value = new THREE.Color(0xacccd9);
      _boat_uniforms['power'].value = 5;

      var fre_material = new THREE.ShaderMaterial({
        uniforms: _boat_uniforms,
        depthWrite: true,
        vertexShader: shader.vertexShader,
        fragmentShader: shader.fragmentShader,
      });

      function setUpBarycentricCoordinates(geometry) {
        let positions = geometry.attributes.position.array;
        let normals = geometry.attributes.normal.array;

        // Build new attribute storing barycentric coordinates
        // for each vertex
        var centers = new THREE.BufferAttribute(
          new Float32Array(positions.length),
          3
        );
        // start with all edges disabled
        for (var f = 0; f < positions.length; f++) {
          centers.array[f] = 1;
        }
        geometry.setAttribute('center', centers);

        var face_centers = new THREE.BufferAttribute(
          new Float32Array(positions.length),
          3
        );
        for (var f = 0; f < positions.length; f += 9) {
          var mid_x =
            (positions[f] + positions[f + 3] + positions[f + 6]) / 3.0;
          var mid_y =
            (positions[f + 1] + positions[f + 4] + positions[f + 7]) / 3.0;
          var mid_z =
            (positions[f + 2] + positions[f + 5] + positions[f + 8]) / 3.0;
          face_centers.array[f] = mid_x;
          face_centers.array[f + 1] = mid_y;
          face_centers.array[f + 2] = mid_z;
          face_centers.array[f + 3] = mid_x;
          face_centers.array[f + 4] = mid_y;
          face_centers.array[f + 5] = mid_z;
          face_centers.array[f + 6] = mid_x;
          face_centers.array[f + 7] = mid_y;
          face_centers.array[f + 8] = mid_z;
        }
        geometry.setAttribute('face_center', face_centers);

        // Hash all the edges and remember which face they're associated with
        // (Adapted from THREE.EdgesHelper)
        function sortFunction(a, b) {
          if (a[0] - b[0] != 0) {
            return a[0] - b[0];
          } else if (a[1] - b[1] != 0) {
            return a[1] - b[1];
          } else {
            return a[2] - b[2];
          }
        }
        var edge = [0, 0];
        var hash = {};
        var face;
        var numEdges = 0;

        for (var i = 0; i < positions.length / 9; i++) {
          var a = i * 9;
          face = [
            [positions[a + 0], positions[a + 1], positions[a + 2]],
            [positions[a + 3], positions[a + 4], positions[a + 5]],
            [positions[a + 6], positions[a + 7], positions[a + 8]],
          ];
          for (var j = 0; j < 3; j++) {
            var k = (j + 1) % 3;
            var b = j * 3;
            var c = k * 3;
            edge[0] = face[j];
            edge[1] = face[k];
            edge.sort(sortFunction);
            key = edge[0] + ' | ' + edge[1];
            if (hash[key] == undefined) {
              hash[key] = {
                face1: a,
                face1vert1: a + b,
                face1vert2: a + c,
                face2: undefined,
                face2vert1: undefined,
                face2vert2: undefined,
              };
              numEdges++;
            } else {
              hash[key].face2 = a;
              hash[key].face2vert1 = a + b;
              hash[key].face2vert2 = a + c;
            }
          }
        }

        var index = 0;
        for (key in hash) {
          h = hash[key];

          // ditch any edges that are bordered by two coplanar faces
          var normal1, normal2;
          if (h.face2 !== undefined) {
            normal1 = new THREE.Vector3(
              normals[h.face1 + 0],
              normals[h.face1 + 1],
              normals[h.face1 + 2]
            );
            normal2 = new THREE.Vector3(
              normals[h.face2 + 0],
              normals[h.face2 + 1],
              normals[h.face2 + 2]
            );
            //if ( normal1.dot( normal2 ) >= 0.9999 ) { continue; }
            if (normal1.dot(normal2) >= 0.3) {
              continue;
            }
          }

          // mark edge vertices as such by altering barycentric coordinates
          var otherVert;
          otherVert = 3 - ((h.face1vert1 / 3) % 3) - ((h.face1vert2 / 3) % 3);
          centers.array[h.face1vert1 + otherVert] = 0;
          centers.array[h.face1vert2 + otherVert] = 0;

          otherVert = 3 - ((h.face2vert1 / 3) % 3) - ((h.face2vert2 / 3) % 3);
          centers.array[h.face2vert1 + otherVert] = 0;
          centers.array[h.face2vert2 + otherVert] = 0;
        }
      }

      var loader = new THREE.FBXLoader();
      loader.load(modelUrl, function(object) {
        object.traverse(function(child) {
          if (child.isMesh && child.geometry.isBufferGeometry) {
            child.material = fre_material;
            child.castShadow = false;
            child.receiveShadow = false;
            let geom = child.geometry;
            setUpBarycentricCoordinates(geom);
            child.geometry = geom;
          }
        });

        _boat = object.children[0];
        _boat.scale.x = 0.825;
        _boat.scale.y = 0.825;
        _boat.scale.z = 0.825;
        onReady();
      });

      _active = true;
    };

    this.remove = function() {
      console.log('remove scene one');
      _active = false;

      _root.scene.remove(_light);
      if (_light) {
        _light = undefined;
      }
      _parameters = null;

      _root.scene.remove(_water);
      if (_water) {
        _water.geometry.dispose();
        _water.material.dispose();
        _water = undefined;
      }

      _root.scene.remove(_boat);
      if (_boat) {
        _boat.geometry.dispose();
        _boat.material.dispose();
        _boat = undefined;
      }

      _root.scene.remove(_cubeCamera);
      if (_cubeCamera) {
        _cubeCamera = undefined;
      }

      _root.scene.remove(_compass);
      if (_compass) {
        _compass.geometry.dispose();
        _compass.material.dispose();
        _compass = undefined;
      }
    };

    this.hideBridge = function() {
      let geometry = polygonLineSurface(AMOUNTX, AMOUNTY, SEPARATION);

      _root.scene.add(_particles);
      _line.geometry.dispose();
      _line.geometry = geometry;
      isBridgeView = false;
    };

    this.addBridge = function() {
      let geometry = polygonLineSurface(32, 64, 64);

      _root.scene.remove(_particles);
      _line.geometry.dispose();
      _line.geometry = geometry;
      isBridgeView = true;
    };

    this.hide = function() {
      if (_boat) {
        _root.scene.remove(_boat);
      }
      _root.scene.remove(_water);
      _root.scene.remove(_light);
      _root.scene.remove(_compass);
    };

    this.show = function() {
      if (_boat) {
        _root.scene.add(_boat);
      }
      _root.scene.add(_water);
      _root.scene.add(_light);
      _root.scene.add(_compass);
    };

    var time = 0;
    this.update = function(delta, heading) {
      if (_active) {
        time += delta * 0.001;

        if (_boat && isBridgeView) {
          let fixPosition = (180 * Math.PI) / 180;
          _boat.rotation.y = (-heading * Math.PI) / 180 + fixPosition;

          _compass.rotation.z = 0;
        } else if (_boat) {
          _boat.rotation.y = Math.sin(time * 2) * 0.01;
          _boat.rotation.x = Math.cos(time) * 0.01;
        }

        _parameters.inclination = 0.25 + Math.sin(time * 0.25) * 0.25;
        _water.material.uniforms['time'].value += delta * 0.00025;

        if (_particles) {
          _particles.material.uniforms['time'].value += delta * 0.001;
        }

        if (_line_shader) {
          _line_shader.uniforms['time'].value += delta * 0.001;
        }
      }
    };

    this.resize = function(wi, he, mob) {};
  };

  return cls;
})();

window.SceneOne = SceneOne;
