const SET_ADDRESS = 'SET_ADDRESS';

const initialState = {
  address: {
    lat: undefined,
    lon: undefined,
    name: 'Location Not Available',
    fetchError: false,
    shipId: undefined,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ADDRESS: {
      return {
        ...state,
        address: action.address,
      };
    }

    default:
      return state;
  }
}

export function setAddress(address) {
  return {
    type: SET_ADDRESS,
    address,
  };
}
