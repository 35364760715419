var Root3D = (function() {
  // constructor
  var cls = function() {
    //THREEJS
    this.renderer;
    this.scene;
    this.camera;
    this.composer;

    var _window;
    var _document;

    var _active = false;

    //public functions
    this.init = function(window, document) {
      _window = window;
      _document = document;

      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(0x212121, 0.0025); //0.00125
      this.scene.background = new THREE.Color(0x212121); //0x1db287);
      this.camera = new THREE.PerspectiveCamera(
        440,
        window.innerWidth / window.innerHeight,
        3,
        20000
      );

      const canvas = document.querySelector('#c');
      const touchContainer = document.querySelector('#touch_container');

      this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = false; //true;
      //this.renderer.toneMapping = THREE.ReinhardToneMapping;
      //this.renderer.toneMappingExposure = Math.pow( 1, 4.0 );

      document.body.appendChild(touchContainer);
      document.body.appendChild(this.renderer.domElement);

      // POST PROCESSING
      this.composer = new THREE.EffectComposer(this.renderer);

      ssaaRenderPass = new THREE.SSAARenderPass(this.scene, this.camera);
      ssaaRenderPass.unbiased = true;
      this.composer.addPass(ssaaRenderPass);

      this.add();
    };

    this.add = function() {
      console.log('add root3d');
      _active = true;
    };

    this.remove = function() {
      console.log('remove root3d');
      _active = false;
    };

    this.render = function(delta) {
      //var time = performance.now() * 0.0001;

      if (_active) {
      }
    };

    this.resize = function(wi, he, mob) {};
  };

  return cls;
})();

window.Root3D = Root3D;
