import Cookies from 'js-cookie';
import humps from 'humps';
import base64 from '../../helpers/base64';
import firebase from '../../helpers/firebase';

const RESET_AUTH_ERR = 'RESET_AUTH_ERR';

const SIGN_IN = 'SIGN_IN';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

const HANDLE_START_ACTIVITY = 'HANDLE_START_ACTIVITY';

const SET_SHIP = 'SET_SHIP';

const SET_SHIPS = 'SET_SHIPS';

const LOGOUT = 'LOGOUT';

const initialState = {
  authError: {},
  user: {},
  login: false,
  loading: false,
  isActivityStarted: true,
  ship: {},
  ships: [],
};

export default function reducer() {
  let cookieOptions = {
    domain: process.env.DOMAIN_NAME || window.location.hostname,
  };

  initialState.user = JSON.parse(Cookies.get('tototheoClient') || '{}');

  if (initialState.user.token) {
    initialState.user = humps.camelizeKeys({
      ...base64.decodeToken(initialState.user.token),
      ...initialState.user,
    });
    initialState.login = true;
  }
  return function(state = initialState, action = {}) {
    switch (action.type) {
      case SET_SHIP: {
        return {
          ...state,
          ship: action.ship,
        };
      }
      case SET_SHIPS: {
        return {
          ...state,
          ships: action.ships,
        };
      }
      case SIGN_IN: {
        return {
          ...state,
          login: false,
          loading: true,
        };
      }

      case SIGN_IN_SUCCESS: {
        Cookies.set('tototheoClient', JSON.stringify(action.result), {
          ...cookieOptions,
        });

        return {
          ...state,
          login: true,
          loading: false,
          user: {
            ...humps.camelizeKeys(base64.decodeToken(action.result.token)),
            token: action.result.token,
          },
        };
      }

      case SIGN_IN_FAIL:
        return {
          ...state,
          login: false,
          loading: false,
          isErrorVisible: true,
          authError: action.error,
        };

      case RESET_AUTH_ERR:
        return {
          ...state,
          authError: {},
        };

      case LOGOUT:
        firebase.logout();
        Cookies.remove('tototheoClient', { ...cookieOptions });
        return {
          ...initialState,
          user: {},
          ship: {},
          login: false,
          logout: true,
        };
      case HANDLE_START_ACTIVITY:
        return {
          ...state,
          isActivityStarted: true,
        };
      default:
        return state;
    }
  };
}

export function signIn(data) {
  return {
    types: [
      SIGN_IN,
      SIGN_IN_SUCCESS,
      SIGN_IN_FAIL,
    ],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_CLIENT_URL}/auth/signin`, {
        data,
      }),
  };
}

export function setShip(ship) {
  return {
    type: SET_SHIP,
    ship: humps.camelizeKeys(ship),
  };
}

export function setShips(ships) {
  return {
    type: SET_SHIPS,
    ships: humps.camelizeKeys(ships),
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function resetAuthErr() {
  return {
    type: RESET_AUTH_ERR,
  };
}
