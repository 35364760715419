import React from 'react';
import { Route } from 'react-router-dom';
import Spinner from '../containers/Spinner';

const App = React.lazy(() => import('../containers/App'));

export default () => (
  <React.Fragment>
    <React.Suspense fallback={<Spinner />}>
      <Route component={Spinner} />
      <Route component={App} />
    </React.Suspense>
  </React.Fragment>
);
