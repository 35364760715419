const SET_WEATHER_LOADING = 'SET_WEATHER_LOADING';
const SET_WEATHER = 'SET_WEATHER';
const RESET_WEATHER = 'RESET_WEATHER';

const initialState = {
  loading: false,
  weather: {},
  forecast: {},
  timezoneOffsetInMinutes: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_WEATHER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_WEATHER: {
      return {
        ...state,
        loading: false,
        weather: action.weather,
        forecast: action.forecast,
        timezoneOffsetInMinutes: action.timezoneOffsetInMinutes,
        location: action.location,
        timestamp: action.timestamp,
      };
    }

    case RESET_WEATHER: {
      return initialState;
    }

    default:
      return state;
  }
}

export function setWeatherLoading() {
  return {
    type: SET_WEATHER_LOADING,
  };
}

export function setWeather({
  weather,
  forecast,
  timezoneOffsetInMinutes,
  location,
  timestamp,
}) {
  return {
    type: SET_WEATHER,
    weather,
    forecast,
    timezoneOffsetInMinutes,
    location,
    timestamp,
  };
}

export function resetWeather() {
  return {
    type: RESET_WEATHER,
  };
}
