var Control3D = (function() {
  // constructor
  var cls = function() {
    //THREEJS

    var _window;
    var _document;
    var _container;
    var _hammer;

    var _active = false;

    var _startMoveXTime = 0;
    var _startMoveYTime = 0;
    var _startX = 0;
    var _startY = 0;

    var _directionX = 1.0;
    var _directionY = 1.0;

    var _adjustScale = 1;
    var _scale = 1;

    var _halt = false;
    this.haltOn = function() {
      _halt = true;
    };

    this.haltOff = function() {
      _halt = false;
    };

    //public functions
    var _moveXTime = 0;
    this.addMoveXTime = function(value) {
      _moveXTime += value * _control_multiplier * _directionX;
    };
    this.moveXTime = function() {
      if (_control_multiplier > 0) {
        return _moveXTime;
      } else {
        _moveXTime = _startMoveXTime + _control_value_x;
        return _moveXTime;
      }
    };

    var _moveYTime = 0;
    this.addMoveYTime = function(value) {
      _moveYTime += value * _control_multiplier * _directionY;
    };
    this.moveYTime = function() {
      if (_control_multiplier > 0) {
        return _moveYTime;
      } else {
        _moveYTime = _startMoveYTime + _control_value_y;
        return _moveYTime;
      }
    };

    var _control_multiplier = 1.0;
    this.ControlMultiplier = function() {
      return _control_multiplier;
    };

    var _control_value_x = 0;
    this.ControlValueX = function() {
      return _startMoveXTime + _control_value_x;
    };

    var _control_value_y = 0;
    this.ControlValueY = function() {
      return _startMoveYTime + _control_value_y;
    };

    this.init = function(window, document, container, hammer) {
      console.log('init Control3D');

      _window = window;
      _document = document;
      _container = container;
      _hammer = hammer;
    };

    var onDown = function(event) {
      if (!_halt) {
        event.preventDefault();

        _control_multiplier = 0;

        _startMoveXTime = _moveXTime;
        _startMoveYTime = _moveYTime;

        if (event.touches) {
          _startX = event.touches[0].clientX;
          _startY = event.touches[0].clientY;
        } else {
          _startX = event.clientX;
          _startY = event.clientY;
        }

        _document.addEventListener('mousemove', onMove, false);
        _document.addEventListener('touchmove', onMove, false);
      }
    };

    var onUp = function(event) {
      if (!_halt) {
        event.preventDefault();

        _control_value_x = 0;
        _control_value_y = 0;
        _control_multiplier = 1;

        _document.removeEventListener('mousemove', onMove, false);
        _document.removeEventListener('touchmove', onMove, false);
      }
    };

    var onMove = function(event) {
      if (!_halt) {
        if (event.touches) {
          _control_value_x = (_startX - event.touches[0].clientX) * 0.005;
          _control_value_y = (_startY - event.touches[0].clientY) * 0.005;
        } else {
          _control_value_x = (_startX - event.clientX) * 0.005;
          _control_value_y = (_startY - event.clientY) * 0.005;
        }

        if (_control_value_x > 0) {
          _directionX = 1.0;
        } else {
          _directionX = -1.0;
        }

        if (_control_value_x > 0) {
          _directionX = 1.0;
        } else {
          _directionX = -1.0;
        }
      }
    };

    this.add = function() {
      if (_active) {
        return;
      }
      _active = true;

      _container.addEventListener('mousedown', onDown, { passive: false });
      _container.addEventListener('mouseup', onUp, { passive: false });

      _container.addEventListener('touchstart', onDown, { passive: false });
      _container.addEventListener('touchend', onUp, { passive: false });

      _container.style.display = 'block';
    };

    this.remove = function() {
      if (!_active) {
        return;
      }
      _active = false;

      _container.removeEventListener('mousedown', onDown, { passive: false });
      _container.removeEventListener('mouseup', onUp, { passive: false });

      _container.removeEventListener('touchstart', onDown, { passive: false });
      _container.removeEventListener('touchend', onUp, { passive: false });

      _container.style.display = 'none';
    };

    this.update = function(delta) {
      //var time = performance.now() * 0.0001;

      if (_active) {
      }
    };

    this.resize = function(wi, he) {};
  };

  return cls;
})();

window.Control3D = Control3D;
