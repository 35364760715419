import { persistCombineReducers, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import spinner from './spinner';
import auth from './auth';
import ships from './ships';
import alert from './alerts';
import address from './address';
import weather from './weather';
import graphs from './graphs';
import shipAlerts from './shipAlerts';

const migrations = {
  '2': state => {
    return {
      ...state,
      address: {
        address: {},
      },
    };
  },
};

const persistConfig = {
  version: '2',
  key: 'persist:root',
  storage: storage,
  blacklist: ['spinner', 'shipAlerts', 'graphs'],
  migrate: createMigrate(migrations, { debug: false }),
};

export default function() {
  const appReducer = persistCombineReducers(persistConfig, {
    auth: auth(),
    ships: ships(),
    alert,
    spinner,
    address,
    weather,
    graphs,
    shipAlerts,
  });

  return (state, action) => appReducer(state, action);
}
