import * as firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAPQ2gaGBYwiQX6DEOlWLpQCmsUErshqjA',
  authDomain: 'tototheo-7993a.firebaseapp.com',
  databaseURL: 'https://tototheo-7993a.firebaseio.com',
  projectId: 'tototheo-7993a',
  storageBucket: 'tototheo-7993a.appspot.com',
  messagingSenderId: '473465642961',
  appId: '1:473465642961:web:51f66d99d1b3021c0acaf0',
};

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
  }

  createReCaptcha() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-verifier',
      {
        size: 'compact',
      }
    );

    window.recaptchaVerifier.render();
  }

  async signIn(signInData, dispatchedSignIn) {
    const recaptchaToken = await window.recaptchaVerifier.verify();
    await dispatchedSignIn({
      ...signInData,
      recaptchaToken,
    });
  }

  async logout() {
    await firebase.auth().signOut();
  }
}

export default new Firebase();
