/**
 */

THREE.GridShader = {
  uniforms: {
    //"mRefractionRatio": { value: 1.02 },
    //"mFresnelBias": { value: 0.1 },
    //"mFresnelPower": { value: 2.0 },
    //"mFresnelScale": { value: 1.0 },
    //"tCube": { value: null }
    color: {
      type: 'c',
      value: new THREE.Color(0xff000), //0x99aaff
    },
    scale: {
      type: 'f',
      value: 1.0,
    },
    time: {
      type: 'f',
      value: 0.0,
    },
  },

  vertexShader: [
    'uniform float time;',

    'attribute float scale;',
    'attribute float index;',

    '	void main() {',

    //"		vec3 offset = vec3(0, 1.0 + sin(time+index) * 1.0 ,0);",

    '		vec3 transformed = vec3(position);',
    '		float freq = 0.13;',
    '		float freq2 = 1.0;',
    '		float ampy = 1.0;',
    '		float angle = (time + position.x)*freq;',
    '		float angle2 = (time + position.z)*freq2;',
    '		transformed.y += sin(angle2)*sin(angle)*ampy;',

    '		vec4 mvPosition = modelViewMatrix * vec4( transformed, 1.0 );',

    '		gl_PointSize = scale * ( 300.0 / - mvPosition.z );',

    '		gl_Position = projectionMatrix * mvPosition;',

    '	}',
  ].join('\n'),

  fragmentShader: [
    //"#extension GL_OES_standard_derivatives: enable",

    'uniform vec3 color;',

    '	void main() {',

    '		if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;',

    '		gl_FragColor = vec4( color, 1.0 );',

    '	}',
  ].join('\n'),
};
