const GET_TIMESERIES_SENSORS = 'GET_TIMESERIES_SENSORS';
const GET_TIMESERIES_SENSORS_SUCCESS = 'GET_TIMESERIES_SENSORS_SUCCESS';
const GET_TIMESERIES_SENSORS_FAIL = 'GET_TIMESERIES_SENSORS_FAIL';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TIMESERIES_SENSORS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TIMESERIES_SENSORS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };

      if (!newState[action.result.step]) {
        newState[action.result.step] = {};
      }

      newState[action.result.step][action.result.id] = action.result.sensors;

      return newState;
    }
    case GET_TIMESERIES_SENSORS_FAIL:
      return {
        ...state,
        isErrorVisible: true,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function getTimeserieSensors(sensorId, step = '2hours') {
  const hour = 'hour';
  const day = 'day';
  const month = 'month';
  const intervalFactor = step === '48hours' ? 15 : 12;
  let interval = '';

  if (step.includes(hour)) {
    let numberInreval = parseInt(step.split(hour)[0], 10) * intervalFactor;

    interval = numberInreval + hour + 's';
  } else if (step.includes(day)) {
    let numberInreval = parseInt(step.split(day)[0], 10) * intervalFactor;

    interval = numberInreval + day + 's';
  } else if (step.includes(month)) {
    let numberInreval = parseInt(step.split(month)[0], 10) * intervalFactor;

    interval = numberInreval + month + 's';
  }

  return {
    types: [
      GET_TIMESERIES_SENSORS,
      GET_TIMESERIES_SENSORS_SUCCESS,
      GET_TIMESERIES_SENSORS_FAIL,
    ],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_CLIENT_URL}/sensors/${sensorId}/timeseries?step=${step}&interval=${interval}`
      ),
  };
}
